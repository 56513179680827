import { useState, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import { MENU_ITEM } from '@/constants'
import AppContext from '@/store/context'

function Header() {
    // Initialize the properties of the header
    const title = "Pynovate Studio - Diverse businesses, one digital destination!"
    const logo = '/brand-icon-circle-192x192.png'
    const url = 'https://www.pynovate.com'

    // Set the state of the menu button
    const [visibility, setVisibility] = useState(false)

    // Set up the items of the navigation bar
    const navigation = [
        { title: "Our Works", id: MENU_ITEM.HOME, path: "/" },
        { title: "Our Website", id: MENU_ITEM.SERVICES, path: url },
        { title: "Contact Us", id: MENU_ITEM.CONTACT,  path: "/contact"}
    ]

    // Retrieve the context object
    const { activeMenuItem } = useContext(AppContext)

    return (
        <nav className="bg-white border-b w-full mx-auto max-w-screen-2xl md:static md:text-sm md:border-none">
            <div className="items-center px-4 max-w-screen-2xl mx-auto md:flex md:px-8">
                <div className="flex items-center justify-between py-3 md:py-5 md:block">
                    <a className="flex items-center" href={url} target="_blank" rel="noopener noreferrer" title={title} aria-label={title}> 
                        <img className="w-12" src={logo} alt={title} />
                        <span className="ml-2 text-2xl text-gray-900 font-semibold">Pynovate Studio</span>
                    </a>
                    <div className="md:hidden">
                        <button className="text-gray-500 hover:text-gray-800"
                            onClick={() => setVisibility(!visibility)}
                        >
                            {
                                visibility ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                                    </svg>
                                )
                            }
                        </button>
                    </div>
                </div>
                <div className={classNames('flex-1 pb-3 mt-8 md:block md:pb-0 md:mt-0', { 'block': visibility, 'hidden': !visibility })}>
                    <ul className="justify-end items-center space-y-6 font-bold text-lg lg:text-xl md:flex md:space-x-6 md:space-y-0">
                        {
                            navigation.map((item, idx) => {
                                return (
                                    <li key={item.id}>
                                        <NavLink to={item.path} className={classNames("text-gray-700 font-medium hover:text-orange-600 hover:underline hover:underline-offset-8 hover:decoration-4 hover:decoration-orange-600 block", {"text-orange-600": item.id === activeMenuItem})}>
                                            {item.title}
                                        </NavLink>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Header